@import "_settings/globalSetting";

.cc-404 {
    position: relative;

    .cc-404-hero {
        position: relative;
    }

    .cc-content-error-img {
        display: block;

        @include media-breakpoint-down(xs) {
            height: 100%;
            width: 100%;
            z-index: 1;
        }

        .cc-error-img {
            display: block;
            margin: 0;
            padding: 0;
            width: 100%;
            object-fit: cover;
            height: calc(100vh - 96px);
            filter: brightness(0.5);

            @include media-breakpoint-down(xs) {
                height: calc(85vh - 96px);
            }
        }
    }

    .cc-content-error-text {
        position: absolute;
        left: 0;
        bottom: 22%;
        z-index: 2;
        width: 100%;
        max-width: rem-calc(900);

        @include media-breakpoint-down(xs) {
            transform: none;
            bottom: 10%;
            max-width: 100%;
        }

        .cc-error-container {
            margin: 0 auto;
            padding: 0 rem-calc(45);

            @include media-breakpoint-down(xs) {
                padding: 0 rem-calc(32);
            }

            .cc-error-title {
                color: $white;
                display: block;
                font-size: rem-calc(64);
                font-family: $secondary-font;
                font-weight: $primary-font-bold;
                line-height: rem-calc(70);
                margin: 0 0 rem-calc(24) 0;
                letter-spacing: rem-calc(.15);
                padding: 0;
                text-transform: uppercase;

                @include media-breakpoint-down(xs) {
                    font-size: rem-calc(46);
                    line-height: rem-calc(56);
                    margin-bottom: rem-calc(20);
                }
            }

            .cc-error-text {
                color: $white;
                display: block;
                font-size: rem-calc(24);
                font-weight: $primary-font-regular;
                line-height: rem-calc(28);
                margin: 0 0 rem-calc(40) 0;
                letter-spacing: rem-calc(.15);
                padding: 0;

                @include media-breakpoint-down(xs) {
                    font-size: rem-calc(20);
                    line-height: rem-calc(24);
                    margin-bottom: rem-calc(30);
                }
            }

            .cc-content-buttons {
                font-size: 0;
                display: flex;
                column-gap: rem-calc(8);


                @include media-breakpoint-down(xs) {
                    flex-direction: column;
                    row-gap: rem-calc(2);
                    margin-bottom: rem-calc(29);

                }

                .cc-button-type-5 {
                    min-width: rem-calc(178);
                    padding: rem-calc(12) rem-calc(24);
                    height: rem-calc(40);

                    @include media-breakpoint-down(xs) {
                        flex: 1;
                        max-width: 60%;
                    }
                }
            }
        }

    }

    .cc-icon-double-arrow {
            position: absolute;
            bottom: 0;
            left: 50%;
            height: rem-calc(24);
            width: rem-calc(24);
            padding: rem-calc(2);
            margin-bottom: rem-calc(64);

            &:hover {
                stroke: $red;
            }

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

    .cc-recommended-grid {
        padding: rem-calc(23) rem-calc(16);

        @include media-breakpoint-up(lg) {
            padding: rem-calc(45) rem-calc(24) rem-calc(60);
        }

        &__carousel {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));

            @include media-breakpoint-up(lg) {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }

            .cc-tile-product-name {
                @include media-breakpoint-down(md) {
                    max-width: 30vw;
                }
            }
        }
    }
}

.cc-500 {
    height: 100vh;
    position: relative;

    .cc-content-error-text {
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        z-index: 1;
    }

    .cc-content-logo {
        margin: 0 auto rem-calc(40) auto;
        width: rem-calc(300);

        @include media-breakpoint-down(xs) {
            width: rem-calc(200);
        }

        .cc-logo {
            display: block;
            height: rem-calc(68);
            width: rem-calc(300);

            @include media-breakpoint-down(xs) {
                height: rem-calc(50);
                width: rem-calc(200);
            }
        }
    }

    .cc-error-title {
        color: $black;
        display: block;
        font-size: rem-calc(25);
        font-weight: $primary-font-semibold;
        line-height: rem-calc(30);
        margin: 0;
        padding: 0 0 rem-calc(13) 0;
        text-align: center;
        text-transform: uppercase;
    }

    .cc-error-text {
        color: $black;
        display: block;
        font-size: rem-calc(20);
        font-style: italic;
        font-weight: $primary-font-regular;
        line-height: rem-calc(28);
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .cc-temp-message {
        bottom: rem-calc(40);
        color: $black;
        left: rem-calc(20);
        position: absolute;
        width: calc(100% - 40px);

        code {
            color: $black;
            display: block;
            text-align: center;
        }
    }
}